import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as Table from 'components/table'
import { flexRender } from '@tanstack/react-table'
import { useOrgPeopleTable } from './org-people-table-context'

export const OrgPeopleTable = observer(() => {
  const { table, loading } = useOrgPeopleTable()

  if (loading) {
    return <div></div> // Replace with a loader if needed.
  }

  const source = 'org-people-table'

  return (
    <div className="overflow-x-auto bg-gray-25 w-full flex-grow pb-24 border-0 border-t border-gray-100 border-solid">
      <Table.Root className="bg-white max-w-full shadow-xs">
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row
              className="bg-white sticky top-0 z-[3]"
              key={headerGroup.id}
              showBottomBorder={false}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Header
                    key={header.id}
                    header={header}
                    source={source}
                    pinned={header.column.getIsPinned()}
                    showColumns={true}
                    stickyHeader={true}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.Header>
                )
              })}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body>
          {table.getRowModel().rows.length === 0 && (
            <Table.NoResultsRow colspan={table.getAllColumns().length}>
              No people found
            </Table.NoResultsRow>
          )}

          {table.getRowModel().rows.map((row) => {
            const cells = row.getVisibleCells()

            return (
              <Table.Row showBottomBorder={true} key={row.id}>
                {cells.map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    pinned={cell.column.getIsPinned()}
                    showColumns={true}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </div>
  )
})
