import { InsightsEngagementPage } from 'pages/insights-engagement-page'
import { InsightsSkillsPage } from 'pages/insights-skills-page'
import { OrgPeoplePage } from 'components/org-people-page'
import { forRails } from 'components/for-rails'
import { registerComponents } from '../src/utils/react/registry'
import { OrgPreferencesPage } from 'components/org-preferences-page'

registerComponents({
  InsightsEngagementPage: forRails(InsightsEngagementPage),
  InsightsSkillsPage: forRails(InsightsSkillsPage),
  OrgPeoplePage: forRails(OrgPeoplePage),
  OrgPreferencesPage: forRails(OrgPreferencesPage),
})
