import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { CheckinsSection } from './checkins-section'
import { FeatureGatePage } from 'pages/feature-gate-page'
import { InsightsFilters } from './insights-skills.types'
import { InsightsSkillsPageVm } from './insights-skills-page-vm'
import { InsightsSecondaryNav } from 'components/insights-secondary-nav'
import { ManagerUserSelect } from 'components/manager-user-select'
import { MonthlyUpdatesSection } from './monthly-updates-section'
import { OnboardingCard } from './onboarding-card'
import { useStore } from 'store/context'

export const InsightsSkillsPage = observer(() => {
  const { currentUser } = useStore()

  const vm = React.useMemo(() => {
    return new InsightsSkillsPageVm()
  }, [])

  const [filters, setFilters] = React.useState<InsightsFilters>(
    vm.defaultFilters
  )

  const [completedActionsPageNumber, setCompletedActionsPageNumber] =
    React.useState(1)

  const [createdActionsPageNumber, setCreatedActionsPageNumber] =
    React.useState(1)

  const [feedbackItemsPageNumber, setFeedbackItemsPageNumber] =
    React.useState(1)

  const [winsPageNumber, setWinsPageNumber] = React.useState(1)

  React.useEffect(() => {
    const fetchCompletedActions = async () => {
      if (currentUser) {
        const meta = await vm.fetchCompletedActions(
          currentUser,
          completedActionsPageNumber
        )

        if (meta?.pages && meta.pages > completedActionsPageNumber) {
          setCompletedActionsPageNumber(completedActionsPageNumber + 1)
        }
      }
    }

    fetchCompletedActions()
  }, [completedActionsPageNumber, currentUser, vm])

  React.useEffect(() => {
    const fetchCreatedActions = async () => {
      if (currentUser) {
        const meta = await vm.fetchCreatedActions(
          currentUser,
          createdActionsPageNumber
        )

        if (meta?.pages && meta.pages > createdActionsPageNumber) {
          setCreatedActionsPageNumber(createdActionsPageNumber + 1)
        }
      }
    }

    fetchCreatedActions()
  }, [createdActionsPageNumber, currentUser, vm])

  React.useEffect(() => {
    const fetchFeedbackItems = async () => {
      if (currentUser) {
        const meta = await vm.fetchFeedbackItems(
          currentUser,
          feedbackItemsPageNumber
        )

        if (meta?.pages && meta.pages > feedbackItemsPageNumber) {
          setFeedbackItemsPageNumber(feedbackItemsPageNumber + 1)
        }
      }
    }

    fetchFeedbackItems()
  }, [currentUser, feedbackItemsPageNumber, vm])

  React.useEffect(() => {
    const fetchWins = async () => {
      if (currentUser) {
        const meta = await vm.fetchWins(currentUser, winsPageNumber)

        if (meta?.pages && meta.pages > winsPageNumber) {
          setWinsPageNumber(winsPageNumber + 1)
        }
      }
    }

    fetchWins()
  }, [currentUser, vm, winsPageNumber])

  const onChange = (filters: InsightsFilters) => {
    setFilters(filters)
    vm.applyFilters(filters)
  }

  if (!currentUser) return null

  const selectedUserText = vm.selectedUserText(filters)

  return (
    <>
      <InsightsSecondaryNav />
      <main className={cn(!vm.canAccessInsights && 'h-full')}>
        {vm.canAccessInsights ? (
          <div className="md:p-10 p-4">
            <OnboardingCard />
            <div className="flex flex-wrap gap-3 items-center">
              <ManagerUserSelect
                defaultValue={filters.user_id}
                onChange={(value) => onChange({ ...filters, user_id: value })}
                user={currentUser}
                showEverybody={currentUser.isAdmin}
              />
            </div>
            <CheckinsSection
              filters={filters}
              selectedUserText={selectedUserText}
              user={currentUser}
            />
            <MonthlyUpdatesSection
              filters={filters}
              selectedUserText={selectedUserText}
              user={currentUser}
            />
          </div>
        ) : (
          <div className="flex flex-1 h-full">
            <FeatureGatePage
              body="Uncover your team’s strengths and weaknesses, and identify skill gaps and position strengths with Insights."
              buttonTitle="Start your free trial"
              title="Discover more with Insights"
            />
          </div>
        )}
      </main>
    </>
  )
})
