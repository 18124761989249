import * as React from 'react'
import { CheckinOverviewCard } from './checkin-overview-card'
import { CheckinOverviewCardsVm } from './checkin-overview-cards-vm'
import { ComparisonDifferenceText } from 'components/comparison-difference-text'

export type CheckinOverviewCardsProps = {
  completedCheckinCount: number
  previousCompletedCheckinCount?: number
  previousTotalCheckinCount?: number
  timePeriodText?: string
  totalCheckinCount: number
  loading?: boolean
}

export const CheckinOverviewCards = (props: CheckinOverviewCardsProps) => {
  const {
    completedCheckinCount,
    previousCompletedCheckinCount,
    previousTotalCheckinCount,
    timePeriodText,
    totalCheckinCount,
    loading,
  } = props

  const vm = React.useMemo(() => {
    return new CheckinOverviewCardsVm(
      completedCheckinCount,
      totalCheckinCount,
      previousCompletedCheckinCount,
      previousTotalCheckinCount
    )
  }, [
    completedCheckinCount,
    previousCompletedCheckinCount,
    previousTotalCheckinCount,
    totalCheckinCount,
  ])

  return (
    <div className="flex flex-col md:flex-row gap-5">
      <CheckinOverviewCard
        loading={loading}
        description="Check-ins started"
        difference={
          <ComparisonDifferenceText
            currentValue={totalCheckinCount}
            previousValue={previousTotalCheckinCount}
            timePeriodText={timePeriodText}
          />
        }
        value={totalCheckinCount}
      />
      <CheckinOverviewCard
        loading={loading}
        description="Check-ins completed"
        difference={
          <ComparisonDifferenceText
            currentValue={completedCheckinCount}
            previousValue={previousCompletedCheckinCount}
            timePeriodText={timePeriodText}
          />
        }
        value={completedCheckinCount}
      />
      <CheckinOverviewCard
        loading={loading}
        description="Completed vs started"
        difference={
          <ComparisonDifferenceText
            currentValue={vm.startedVsCompletedPercentage}
            previousValue={vm.previousStartedVsCompletedPercentage}
            timePeriodText={timePeriodText}
          />
        }
        value={vm.percentageText}
      />
    </div>
  )
}
