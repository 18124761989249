import { Question } from '@phosphor-icons/react'
import { Tooltip } from 'src/design-system'
import * as React from 'react'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export type InfoTooltipProps = {
  content: string
  eventName: string
  title: string
}

export const InfoTooltip: React.VFC<InfoTooltipProps> = (props) => {
  const { content, eventName, title } = props

  return (
    <Tooltip content={content}>
      <div className="flex items-center">
        <Question
          alt=""
          className="h-3.5 text-gray-600 w-3.5"
          onMouseEnter={() => trackEvent(eventName, { title })}
          weight="bold"
        />
      </div>
    </Tooltip>
  )
}
