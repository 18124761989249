import { AvatarButton, Tag } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { DataCollectedOverTimeChart } from 'components/data-collected-over-time-chart'
import { formatDate } from 'app/packs/src/utils/date-helpers'
import { InfoTooltip } from 'components/info-tooltip'
import { InsightsFilters } from '../insights-skills.types'
import { MonthlyUpdatesCard } from './monthly-updates-card'
import { MonthlyUpdatesSectionVm } from './monthly-updates-section-vm'
import { User } from 'store/modules/users'

export type MonthlyUpdatesSectionProps = {
  filters: InsightsFilters
  selectedUserText: string
  user: User
}

export const MonthlyUpdatesSection = observer(
  (props: MonthlyUpdatesSectionProps) => {
    const { filters, selectedUserText, user } = props

    const [hoveredKey, setHoveredKey] = React.useState('')

    const vm = React.useMemo(() => {
      return new MonthlyUpdatesSectionVm(filters, hoveredKey, user)
    }, [filters, hoveredKey, user])

    const additionalTooltipInformation =
      vm.userIds.length > 1 ? (
        <MostUpdatesForMonthInformation mostUpdates={vm.mostUpdatesForMonth} />
      ) : undefined

    return (
      <>
        <div className="flex flex-col md:flex-row justify-center md:justify-start md:items-center gap-x-2 mb-5 mt-16">
          <p className="mb-0 text-xl font-bold">Updates - last 30 days</p>
          <p className="mb-0 text-sm text-gray-600">{selectedUserText}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-10">
          {vm.updateTypes.map((updateType) => (
            <MonthlyUpdatesCard
              key={updateType.type}
              vm={vm}
              type={updateType.type}
              icon={updateType.icon}
              counts={updateType.counts}
              loading={vm.loading}
            />
          ))}
        </div>
        <div className="flex">
          <div className="flex gap-1 items-center mb-7 rounded-sm px-1 -mx-1">
            <h2 className="font-bold text-base text-gray-900">
              Updates by day
            </h2>
            <InfoTooltip
              content="This chart shows the updates (Wins received, Feedback received, Actions completed and Notes) logged: evidence of the progress made."
              eventName="$track_report_insights_page_tooltip_hovered"
              title="Updates by day"
            />
          </div>
        </div>
        <div className="w-full h-full -mt-4 mb-9 flex flex-col items-center justify-center relative">
          <div className="w-full flex items-center justify-center h-[100px] max-h-[100px]">
            <DataCollectedOverTimeChart
              additionalTooltipInformation={additionalTooltipInformation}
              barKeys={vm.barKeys}
              data={vm.isEmpty ? vm.emptyChartData : vm.chartData}
              isEmpty={vm.isEmpty}
              onMouseEnter={(payload) => setHoveredKey(payload.key)}
              onMouseOut={() => setHoveredKey('')}
            />
          </div>
          <p className="mb-0 mt-2 text-sm text-gray-600">
            {formatDate(vm.thirtyDaysAgo)}
            {' - '}
            {formatDate(vm.dateToday)}
          </p>
        </div>
      </>
    )
  }
)

const MostUpdatesForMonthInformation = (props: {
  mostUpdates: { count: number; user: User }[]
}) => {
  const { mostUpdates } = props

  return (
    <>
      <p className="font-semibold mb-2.5 mt-3 text-gray-900 text-sm">
        Most updates - last 30 days
      </p>
      {mostUpdates.map((userCount) => (
        <div
          className="flex flex-row items-center gap-x-2"
          key={userCount.user.id}
        >
          <AvatarButton
            alt={userCount.user.fullName}
            fallbackText={userCount.user.initials}
            href={`/userCount.users/${userCount.user.slug}`}
            isInactive={userCount.user.state !== 'active'}
            size="xs"
            src={userCount.user.avatarUrl}
          />
          {userCount.user.fullName}
          <Tag variant="paper" className="ml-auto py-0.5">
            {userCount.count}
          </Tag>
        </div>
      ))}
    </>
  )
}
