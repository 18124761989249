import * as React from 'react'
import { EngagementSection } from './engagement-section'
import { InsightsSecondaryNav } from 'components/insights-secondary-nav'

export const InsightsEngagementPage = () => {
  return (
    <>
      <InsightsSecondaryNav />
      <main>
        <EngagementSection />
      </main>
    </>
  )
}
