import { Dropdown } from 'src/design-system'
import * as React from 'react'
import { store } from 'store/index'
import { useOrgPeopleTable } from '../org-people-table/org-people-table-context'

export const ExportUsersDropdown = () => {
  const { downloadCsvData } = useOrgPeopleTable()

  const org = store.currentUser?.org

  if (!org) return null

  const downloadPath = `${org.basePath}/export_users.csv`

  return (
    <Dropdown.Root>
      <Dropdown.Trigger
        variant="styled"
        colourVariant="subtle"
        variantStyle="outline"
        size="nav"
      >
        Export users
      </Dropdown.Trigger>
      <Dropdown.Content className="mr-8 w-[180px]">
        <Dropdown.Link
          className="text-left text-gray-900"
          data-turbo-action="advance"
          href={`${downloadPath}?type=all`}
        >
          Export all users
        </Dropdown.Link>
        <Dropdown.Link
          className="text-left text-gray-900"
          data-turbo-action="advance"
          href={`${downloadPath}?type=active`}
        >
          Export active users
        </Dropdown.Link>
        <Dropdown.Button
          className="text-left"
          onClick={() => downloadCsvData()}
        >
          Export filtered users
        </Dropdown.Button>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
