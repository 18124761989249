import { AnimatePresence, motion } from 'framer-motion'
import { useTimedContent } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export const OnboardingCard = () => {
  const { show, hideFor } = useTimedContent('reportInsightsOnboardingCard')

  const onClick = () => {
    hideFor('forever')
    trackEvent('Report insights onboarding card dismissed')
  }

  return (
    <div className="flex justify-center">
      <AnimatePresence>
        {show && (
          <motion.div
            className="bg-blue-100 mb-10 p-4 sm:p-6 rounded-lg"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 'auto', opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
          >
            <div className="flex justify-end items-start">
              <button
                className="flex items-center justify-center gap-1 text-blue-700 text-sm"
                onClick={onClick}
              >
                Hide
                <X aria-hidden weight="bold" />
              </button>
            </div>
            <div className="font-bold mb-3 text-xl md:text-2xl text-blue-900">
              Keep on top of your team’s development with manager insights.
            </div>
            <p className="text-blue-700 text-base mb-6">
              View your team’s aggregated skill levels and the updates they’ve
              added in Progression in a collective or individual view. Discover
              your team’s strengths, who needs support and what you can do to
              help. It all lives here.
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
