import { Avatar, Tag } from 'src/design-system'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { observer } from 'mobx-react-lite'
import { TooltipProps } from 'recharts'
import * as React from 'react'
import cn from 'classnames'
import { CheckinSkillInsight } from '../insights-skills.types'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { RadarChartHoverCardVm } from './radar-chart-hover-card-vm'

export const RadarChartHoverCard = observer(
  (
    props: TooltipProps<ValueType, NameType> & {
      data: CheckinSkillInsight[]
    }
  ) => {
    const { data, payload } = props

    const chartData = payload?.[0]

    const skillId = chartData?.payload?.id

    const vm = React.useMemo(() => {
      return new RadarChartHoverCardVm(data, skillId)
    }, [data, skillId])

    if (!chartData) return null

    return (
      <div className="bg-white border border-gray-100 flex flex-col gap-y-1 min-w-[220px] p-3 rounded text-xs w-auto whitespace-nowrap">
        <p className="font-semibold mb-0 text-gray-900">
          {chartData.payload.label}
        </p>
        <p className="mb-3 text-gray-600">{chartData.payload.category}</p>
        <div className="flex gap-2 items-center mb-3">
          <CheckinStatusLabel
            status={vm.status(
              chartData.payload.reports,
              chartData.payload.required
            )}
          />
          <p className="mb-0 text-gray-600">{vm.numberOfCheckinText}</p>
        </div>
        {vm.users.map((user) => (
          <div className="flex justify-between items-center" key={user.id}>
            <div className="flex gap-2 items-center">
              <Avatar
                alt={`Avatar for ${user.fullName}`}
                className="border-gray-100 border-px border-solid"
                fallbackText={user.initials}
                size="xs"
                src={user.avatarUrl}
              />
              <p className="mb-0 text-gray-600">{user.fullName}</p>
            </div>
            <div className="flex gap-1 items-center">
              <Tag variant="paper">L{user.level}</Tag>
              <span
                className={cn(
                  vm.blobColour(user.status),
                  'blob mr-0 level-text-label pr-0'
                )}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
)
