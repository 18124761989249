import { Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  ActionCounts,
  FeedbackCounts,
  InsightsFilters,
  NoteCounts,
  WinCounts,
} from '../insights-engagement-page.types'
import { UpdatesCard } from './updates-card'
import { UpdatesSectionVm } from './updates-section-vm'

type UpdatesSectionProps = {
  actionCounts: ActionCounts
  feedbackCounts: FeedbackCounts
  filters: InsightsFilters
  noteCounts: NoteCounts
  setActionCounts: (count: ActionCounts) => void
  setFeedbackCounts: (count: FeedbackCounts) => void
  setNoteCounts: (count: NoteCounts) => void
  setWinCounts: (count: WinCounts) => void
  timeText?: string
  winCounts: WinCounts
}

export const UpdatesSection = observer((props: UpdatesSectionProps) => {
  const {
    actionCounts,
    feedbackCounts,
    filters,
    noteCounts,
    setActionCounts,
    setFeedbackCounts,
    setNoteCounts,
    setWinCounts,
    timeText,
    winCounts,
  } = props

  const [loading, setLoading] = React.useState(true)

  const vm = React.useMemo(() => {
    return new UpdatesSectionVm()
  }, [])

  const counts = React.useMemo(() => {
    return {
      Actions: actionCounts,
      Feedback: feedbackCounts,
      Notes: noteCounts,
      Wins: winCounts,
    }
  }, [actionCounts, feedbackCounts, noteCounts, winCounts])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchActionCounts = async () => {
        setActionCounts(await vm.fetchActionCounts(filters))
      }

      const fetchFeedbackCounts = async () => {
        setFeedbackCounts(await vm.fetchFeedbackCounts(filters))
      }

      const fetchNoteCounts = async () => {
        setNoteCounts(await vm.fetchNoteCounts(filters))
      }

      const fetchWinCounts = async () => {
        setWinCounts(await vm.fetchWinCounts(filters))
      }

      setLoading(true)

      await Promise.all([
        fetchActionCounts(),
        fetchFeedbackCounts(),
        fetchNoteCounts(),
        fetchWinCounts(),
      ])

      setLoading(false)
    }

    fetchData()
  }, [filters, setActionCounts, setFeedbackCounts, setNoteCounts, setWinCounts])

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap gap-x-2 items-baseline">
        <p className="font-bold mb-0 text-xl">Updates</p>
        {timeText && <p className="mb-0 text-gray-600 text-sm">{timeText}</p>}
      </div>
      <>
        <div className="gap-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          {vm.updateTypes.map((updateType) => (
            <UpdatesCard
              counts={counts[updateType.type]}
              icon={updateType.icon}
              key={updateType.type}
              timePeriodText={timeText}
              type={updateType.type}
              loading={loading}
            />
          ))}
        </div>
        {vm.orgWinsUrl && (
          <div className="flex justify-center">
            <Link
              href={vm.orgWinsUrl}
              underline={false}
              data-turbo-frame="content"
              data-turbo-action="advance"
            >
              View Org Wins
            </Link>
          </div>
        )}
      </>
    </div>
  )
})
