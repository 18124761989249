import * as SecondaryNav from 'components/secondary-nav'
import * as React from 'react'
import { Button } from 'src/design-system'
import { InviteModalButton } from 'components/invite-modal'
import { ExportUsersDropdown } from './export-users-dropdown'

export const OrgPeoplePageHeader = () => {
  return (
    <SecondaryNav.Root>
      <SecondaryNav.Header>People</SecondaryNav.Header>
      <SecondaryNav.Actions>
        <Button
          as="a"
          href="/user_imports/new"
          variant="outline"
          colourVariant="subtle"
          size="nav"
          data-turbo-action="advance"
        >
          Import and sync
        </Button>
        <ExportUsersDropdown />
        <InviteModalButton intent="org-people-page" />
      </SecondaryNav.Actions>
    </SecondaryNav.Root>
  )
}
