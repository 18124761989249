import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { openModal } from 'app/packs/src/utils/open-modal'
import { OverflowMenu } from 'src/design-system'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { User } from 'store/modules/users'

export const ActionsCell = observer((data: CellContext<User, unknown>) => {
  const user = data.row.original
  const isAdmin = user.isAdmin
  const org = user.org
  const isLastAdmin = isAdmin && !!org?.onlyOneAdmin

  const onEditUser = () => {
    openModal(`/org_users/${user.id}/edit?find_by_user_id=true`)
  }

  const AdminButton = () => {
    if (user.status === 'Archived') return null

    const onClick = async (orgRole: 'admin' | 'member') => {
      const result = await store.users.update(user.id, { orgRole })

      if (result.success) {
        successToast(`Admin ${orgRole === 'admin' ? 'added' : 'removed'}`)
      } else {
        errorToast()
      }
    }

    if (isAdmin)
      return (
        <OverflowMenu.Button
          disabled={isLastAdmin}
          onClick={() => onClick('member')}
          type="submit"
          variant="destructive"
        >
          Remove as admin
        </OverflowMenu.Button>
      )

    return (
      <OverflowMenu.Button onClick={() => onClick('admin')} type="submit">
        Add as admin
      </OverflowMenu.Button>
    )
  }

  const ArchiveUserButton = () => {
    if (user.directReports.length > 0) {
      const onClick = () => {
        openModal(
          `/users/${user.slug}/archive_and_transfer_reports_modal?status=0`
        )
      }

      return (
        <OverflowMenu.Button
          disabled={isLastAdmin}
          onClick={onClick}
          type="button"
        >
          Archive user
        </OverflowMenu.Button>
      )
    }

    const onClick = async () => {
      const state = user.state === 'archived' ? 'active' : 'archived'
      const result = await store.users.update(user.id, { state })

      if (result.success) {
        successToast(`User ${state === 'archived' ? 'archived' : 'restored'}`)
      } else {
        errorToast()
      }
    }

    return (
      <OverflowMenu.Button
        disabled={user.status !== 'Archived' && isLastAdmin}
        onClick={onClick}
        type="submit"
      >
        {user.state === 'archived' ? 'Restore user' : 'Archive user'}
      </OverflowMenu.Button>
    )
  }

  const EditInviteButton = () => {
    if (user.currentSignInAt || user.status === 'Archived') return null

    return (
      <OverflowMenu.Link className="text-gray-900" href={user.editUrl}>
        Edit invite
      </OverflowMenu.Link>
    )
  }

  return (
    <div className="flex flex-row gap-x-3 items-center whitespace-nowrap justify-center">
      <OverflowMenu.Root contentClassName="min-w-[160px] translate-x-0	" right>
        {user.status !== 'Archived' && (
          <OverflowMenu.Button onClick={onEditUser}>
            Edit user
          </OverflowMenu.Button>
        )}
        <AdminButton />
        <ArchiveUserButton />
        <EditInviteButton />
      </OverflowMenu.Root>
    </div>
  )
})
