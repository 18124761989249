import {
  ArrowDown,
  ArrowUp,
  Checks,
  CircleWavyCheck,
  Megaphone,
  Note,
} from '@phosphor-icons/react'
import { add, format, isSameDay, sub } from 'date-fns'
import React from 'react'
import {
  ActionCounts,
  FeedbackCounts,
  InsightsFilters,
  NoteCounts,
  WinCounts,
} from '../insights-skills.types'
import { convertValueToUserIds } from 'components/manager-user-select/utils'
import { DataCollectedOverTimeUpdateTypes } from 'components/data-collected-over-time-chart'
import { dateRange } from 'app/packs/src/utils/date-helpers'
import { omit } from 'app/packs/src/utils/omit'
import { store } from 'store/index'
import { UpdateType } from 'app/packs/src/types/updates'
import { User } from 'store/modules/users'

type GraphData = {
  key: string
  actions?: number
  feedback?: number
  wins?: number
  fillOpacity: number
}

type GraphBuildOption = {
  amount: number
  graphKeyFormat: string
  rangeStep: 'day'
}

export const iconMap = {
  down: ArrowDown,
  up: ArrowUp,
}

export class MonthlyUpdatesSectionVm {
  constructor(
    private filters: InsightsFilters,
    private hoveredKey: string,
    private user: User
  ) {}

  get dateToday() {
    return new Date()
  }

  get thirtyDaysAgo() {
    return add(sub(this.dateToday, { days: 30 }), { days: 1 })
  }

  get userIds() {
    return convertValueToUserIds(this.user, this.filters.user_id)
  }

  get loading() {
    return (
      store.wins.loading || store.actions.loading || store.feedbackItems.loading
    )
  }

  get updateTypes() {
    return [
      {
        type: 'Wins',
        icon: <CircleWavyCheck weight="bold" className="text-green-400" />,
        counts: this.winsCounts,
      },
      {
        type: 'Actions',
        icon: <Checks weight="bold" className="text-green-600" />,
        counts: this.actionsCounts,
      },
      {
        type: 'Feedback',
        icon: <Megaphone weight="bold" className="text-yellow-600" />,
        counts: this.feedbackItemsCounts,
      },
      {
        type: 'Notes',
        icon: <Note weight="bold" className="text-blue-600" />,
        counts: this.notesCounts,
      },
    ]
  }

  compareIconType(previousTotal: number, currentTotal: number) {
    return currentTotal < previousTotal ? 'down' : 'up'
  }

  compareTextClassName(previousTotal: number, currentTotal: number) {
    if (currentTotal > previousTotal) {
      return 'text-green-700'
    } else if (currentTotal === previousTotal) {
      return 'text-gray-600'
    } else {
      return 'text-red-600'
    }
  }

  comparePercentageText(previousTotal: number, currentTotal: number) {
    if (currentTotal === previousTotal) return '0% vs '

    const percentage =
      previousTotal === 0
        ? undefined
        : Number(
            (((currentTotal - previousTotal) / previousTotal) * 100).toFixed()
          )

    let text = ''
    if (currentTotal > previousTotal) {
      if (percentage) text = `${percentage}% vs `
    } else {
      if (percentage) text = `${Math.abs(percentage)}% vs `
    }

    return text
  }

  get actionsCounts() {
    const counts: ActionCounts = {
      breakdown: { created: 0, completed: 0 },
      currentTotal: 0,
      previousTotal: 0,
    }

    const actionsCreatedThisMonth = store.actions.createdSinceDateTime(
      this.thirtyDaysAgo,
      { userIds: this.userIds }
    ).length

    const actionsCompletedThisMonth = store.actions.completedSinceDateTime(
      this.thirtyDaysAgo,
      { userIds: this.userIds }
    ).length

    const actionsCreatedLastMonth = store.actions.createdSinceDateTime(
      this.sixtyDaysAgo,
      { userIds: this.userIds },
      this.thirtyDaysAgo
    ).length

    const actionsCompletedLastMonth = store.actions.completedSinceDateTime(
      this.sixtyDaysAgo,
      { userIds: this.userIds },
      this.thirtyDaysAgo
    ).length

    counts.breakdown.created += actionsCreatedThisMonth
    counts.breakdown.completed += actionsCompletedThisMonth
    counts.currentTotal += actionsCreatedThisMonth + actionsCompletedThisMonth
    counts.previousTotal += actionsCreatedLastMonth + actionsCompletedLastMonth

    return counts
  }

  get feedbackItemsCounts() {
    const counts: FeedbackCounts = {
      breakdown: { given: 0, received: 0 },
      currentTotal: 0,
      previousTotal: 0,
    }

    const feedbackGivenThisMonth = store.feedbackItems.givenSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo
    ).length

    const feedbackReceivedThisMonth = store.feedbackItems.forUsersSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo
    ).length

    const feedbackGivenLastMonth = store.feedbackItems.givenSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      this.thirtyDaysAgo
    ).length

    const feedbackReceivedLastMonth = store.feedbackItems.forUsersSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      [],
      this.thirtyDaysAgo
    ).length

    counts.breakdown.given += feedbackGivenThisMonth
    counts.breakdown.received += feedbackReceivedThisMonth
    counts.currentTotal += feedbackGivenThisMonth + feedbackReceivedThisMonth
    counts.previousTotal += feedbackGivenLastMonth + feedbackReceivedLastMonth

    return counts
  }

  get winsCounts() {
    const counts: WinCounts = {
      breakdown: { given: 0, received: 0 },
      currentTotal: 0,
      previousTotal: 0,
    }

    const winsGivenThisMonth = store.wins.givenSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo
    ).length

    const winsReceivedThisMonth = store.wins.forWinnersSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo
    ).length

    const winsGivenLastMonth = store.wins.givenSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      this.thirtyDaysAgo
    ).length

    const winsReceivedLastMonth = store.wins.forWinnersSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      [],
      this.thirtyDaysAgo
    ).length

    counts.breakdown.given += winsGivenThisMonth
    counts.breakdown.received += winsReceivedThisMonth
    counts.currentTotal += winsGivenThisMonth + winsReceivedThisMonth
    counts.previousTotal += winsGivenLastMonth + winsReceivedLastMonth

    return counts
  }

  get notesCounts() {
    const counts: NoteCounts = {
      breakdown: { public: 0, personal: 0 },
      currentTotal: 0,
      previousTotal: 0,
    }

    const publicNotesThisMonth = store.wins.notesForUsersSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo,
      [],
      this.dateToday,
      { visibility: 'reporting_line' }
    ).length

    const personalNotesThisMonth = store.wins.notesForUsersSinceDateTime(
      this.userIds,
      this.thirtyDaysAgo,
      [],
      this.dateToday,
      { visibility: 'only_me' }
    ).length

    const publicNotesLastMonth = store.wins.notesForUsersSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      [],
      this.thirtyDaysAgo,
      { visibility: 'reporting_line' }
    ).length

    const personalNotesLastMonth = store.wins.notesForUsersSinceDateTime(
      this.userIds,
      this.sixtyDaysAgo,
      [],
      this.thirtyDaysAgo,
      { visibility: 'only_me' }
    ).length

    counts.breakdown.public += publicNotesThisMonth
    counts.breakdown.personal += personalNotesThisMonth
    counts.currentTotal += publicNotesThisMonth + personalNotesThisMonth
    counts.previousTotal += publicNotesLastMonth + personalNotesLastMonth

    return counts
  }

  // Chart
  get barKeys(): (UpdateType | 'empty')[] {
    return this.isEmpty ? ['empty'] : DataCollectedOverTimeUpdateTypes
  }

  get chartData(): GraphData[] {
    return this.dateRange.map((date) => {
      const key = format(date, this.buildOptions.graphKeyFormat)
      const fillOpacity = [key, ''].includes(this.hoveredKey) ? 1 : 0.5

      return {
        actions: this.actionsForDate(date).length,
        feedback_received: this.feedbackItemsForDate(date).length,
        fillOpacity,
        key,
        notes: this.notesForDate(date).length,
        wins: this.winsForDate(date).length,
      }
    })
  }

  get emptyChartData(): GraphData[] {
    const min = 3
    const max = 30

    return this.dateRange.map((date) => {
      return {
        empty: Math.random() * (max - min) + min,
        fillOpacity: 1,
        key: format(date, this.buildOptions.graphKeyFormat),
      }
    })
  }

  get isEmpty() {
    return this.chartData.every((item) => {
      return Object.values(omit(item, 'fillOpacity')).every((value) => {
        return typeof value !== 'number' || value === 0
      })
    })
  }

  get mostUpdatesForMonth() {
    const highestCount = this.userCounts.sort((a, b) =>
      a.count > b.count ? -1 : 1
    )[0].count

    return this.userCounts.filter(
      (userCount) => userCount.count === highestCount
    )
  }

  private get buildOptions(): GraphBuildOption {
    return {
      amount: 30,
      graphKeyFormat: 'yyyy-MM-dd',
      rangeStep: 'day',
    }
  }

  private get dateRange() {
    return dateRange(this.buildOptions.rangeStep, this.buildOptions.amount)
  }

  private get sixtyDaysAgo() {
    return add(sub(this.dateToday, { days: 60 }), { days: 1 })
  }

  private get userCounts() {
    return store.users
      .filtered((user) => this.userIds.includes(user.id))
      .reduce<{ count: number; user: User }[]>((counts, user) => {
        let count = 0

        count += store.actions.completedSinceDateTime(
          this.thirtyDaysAgo,
          { userIds: [user.id] },
          this.dateToday
        ).length

        count += store.feedbackItems.forUsersSinceDateTime(
          [user.id],
          this.thirtyDaysAgo,
          [],
          this.dateToday
        ).length

        count += store.wins.notesForUsersSinceDateTime(
          [user.id],
          this.thirtyDaysAgo,
          [],
          this.dateToday
        ).length

        count += store.wins.forWinnersSinceDateTime(
          [user.id],
          this.thirtyDaysAgo,
          [],
          this.dateToday
        ).length

        counts.push({ count, user })

        return counts
      }, [])
  }

  private actionsForDate(date: Date) {
    return store.actions
      .forUsers(this.userIds)
      .filter(
        (action) => action.completedAt && isSameDay(action.completedAt, date)
      )
  }

  private feedbackItemsForDate(date: Date) {
    return store.feedbackItems
      .forUsers(this.userIds)
      .filter((feedbackItem) => isSameDay(feedbackItem.createdAt, date))
  }

  private notesForDate(date: Date) {
    return store.wins
      .notesForUsers(this.userIds)
      .filter((note) => isSameDay(note.tookPlaceOn, date))
  }

  private winsForDate(date: Date) {
    return store.wins
      .forWinners(this.userIds)
      .filter((win) => isSameDay(win.tookPlaceOn, date))
  }
}
