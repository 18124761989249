import { ArrowDown, ArrowUp } from '@phosphor-icons/react'

export const className = (currentValue: number, previousValue: number) => {
  if (currentValue > previousValue) {
    return 'text-green-700'
  } else if (currentValue === previousValue) {
    return 'text-gray-600'
  } else {
    return 'text-red-600'
  }
}

export const countText = (currentValue: number, previousValue: number) => {
  const value = Math.abs(currentValue - previousValue)

  return value % 1 === 0 ? value.toString() : value.toFixed(1)
}

export const iconMap = { down: ArrowDown, up: ArrowUp }

export const iconType = (currentValue: number, previousValue: number) => {
  return currentValue < previousValue ? 'down' : 'up'
}
