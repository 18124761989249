import { Select } from 'components/atoms/select'
import { Flag } from '@phosphor-icons/react'
import * as React from 'react'
import { OrgPeopleStatus } from '../org-people-table/data'

export type StatusFilterProps = {
  onChange: (value: string) => void
  value: string | undefined
}

export const StatusFilter = (props: StatusFilterProps) => {
  const { onChange, value } = props

  const defaultLabel = 'Active'

  const statusOptions = [
    { label: defaultLabel, value: OrgPeopleStatus.Active },
    { label: 'Archived', value: OrgPeopleStatus.Archived },
    { label: 'All statuses', value: OrgPeopleStatus.All },
  ]

  return (
    <Select
      isMulti={false}
      onChange={onChange}
      options={statusOptions}
      triggerContent={
        <div className="flex gap-1 items-center">
          <Flag aria-hidden className="h-3 text-gray-600 w-3" weight="bold" />
          {defaultLabel}
        </div>
      }
      value={value}
    />
  )
}
