import * as React from 'react'
import updateEmptyBars from '../../../images/update-empty-bars.svg'

export const EmptyUpdateHoverCard = () => {
  return (
    <div className="whitespace-nowrap w-auto rounded border text-gray-600 border-gray-100 p-5 bg-white flex flex-col items-center">
      <img
        alt=""
        src={updateEmptyBars}
        className="mb-2.5"
        width="44"
        height="30"
      />
      <strong className="text-gray-900 font-bold text-sm">
        Nothing to see here
      </strong>
      <p className="m-0 text-gray-600 text-xs">Your updates will appear here</p>
    </div>
  )
}
