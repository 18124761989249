import {
  ActionCounts,
  DEFAULT_TIME_VALUE,
  FeedbackCounts,
  InsightsFilters,
  NoteCounts,
  TIME_VALUES,
  WinCounts,
} from '../insights-engagement-page.types'

export class EngagementSectionVm {
  constructor() {}

  get defaultFilters(): InsightsFilters {
    return { time: DEFAULT_TIME_VALUE }
  }

  get timeOptions() {
    return Object.entries(TIME_VALUES).map(([value, label]) => {
      return { label, value }
    })
  }

  engagementText(
    actionCounts: ActionCounts,
    completedCheckinCount: number,
    feedbackCounts: FeedbackCounts,
    filters: InsightsFilters,
    noteCounts: NoteCounts,
    totalCheckinCount: number,
    winCounts: WinCounts
  ) {
    return `In the last ${this.timeText(filters)} ${this.updatesEngagementText(
      actionCounts,
      feedbackCounts,
      noteCounts,
      winCounts
    )}, and ${this.checkinsEngagementText(
      completedCheckinCount,
      totalCheckinCount
    )}`
  }

  timeText(filters: InsightsFilters) {
    return filters.time ? TIME_VALUES[filters.time] : undefined
  }

  private checkinsEngagementText(
    completedCheckinCount: number,
    totalCheckinCount: number
  ) {
    const percentage =
      totalCheckinCount === 0
        ? 0
        : (completedCheckinCount / totalCheckinCount) * 100

    return `${percentage.toFixed(1)}% of Check-ins started have been completed.`
  }

  private updatesEngagementText(
    actionCounts: ActionCounts,
    feedbackCounts: FeedbackCounts,
    noteCounts: NoteCounts,
    winCounts: WinCounts
  ) {
    let text = 'team engagement is '

    const updateCounts = [actionCounts, feedbackCounts, noteCounts, winCounts]

    const currentTotal = updateCounts.reduce((total, counts) => {
      return total + counts.currentTotal
    }, 0)

    const previousTotal = updateCounts.reduce((total, counts) => {
      return total + (counts.previousTotal || 0)
    }, 0)

    if (currentTotal === previousTotal) {
      text += 'the same'
    } else {
      const percentage =
        previousTotal === 0
          ? undefined
          : Math.abs(
              Number(
                (
                  ((currentTotal - previousTotal) / previousTotal) *
                  100
                ).toFixed()
              )
            )

      if (currentTotal > previousTotal) {
        text += percentage ? `up ${percentage}%` : 'up'
      } else {
        text += percentage ? `down ${percentage}%` : 'down'
      }
    }

    return text
  }
}
