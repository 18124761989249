import { Button } from 'src/design-system'
import { MinusCircle } from '@phosphor-icons/react'
import * as React from 'react'

export type ClearFilterButtonProps = {
  onClick: () => void
}

export const ClearFilterButton = (props: ClearFilterButtonProps) => {
  const { onClick } = props

  return (
    <Button className="h-[34px]" onClick={onClick} variant="outline">
      <MinusCircle
        aria-hidden
        className="h-4 text-theme-40 w-4"
        weight="bold"
      />
      Clear filters
    </Button>
  )
}
