import { CalendarBlank, Sparkle, UsersThree } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  ActionCounts,
  FeedbackCounts,
  InsightsFilters,
  NoteCounts,
  TimeValue,
  WinCounts,
} from '../insights-engagement-page.types'
import { CheckinsSection } from '../checkins-section'
import { EngagementSectionVm } from './engagement-section-vm'
import { Select } from 'components/atoms/select'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { UpdatesSection } from '../updates-section'
import { useStore } from 'store/context'

export const EngagementSection = observer(() => {
  const {
    actions,
    checkins,
    currentUser,
    feedbackItems,
    feedbackRequests,
    wins,
  } = useStore()

  const vm = React.useMemo(() => {
    return new EngagementSectionVm()
  }, [])

  const [filters, setFilters] = React.useState<InsightsFilters>(
    vm.defaultFilters
  )

  // Check-ins
  const [completedCheckinCount, setCompletedCheckinCount] = React.useState(0)
  const [totalCheckinCount, setTotalCheckinCount] = React.useState(0)

  const [previousCompletedCheckinCount, setPreviousCompletedCheckinCount] =
    React.useState<number | undefined>(undefined)

  const [previousTotalCheckinCount, setPreviousTotalCheckinCount] =
    React.useState<number | undefined>(undefined)

  // Updates
  const [actionCounts, setActionCounts] = React.useState<ActionCounts>({
    breakdown: { created: 0, completed: 0 },
    currentTotal: 0,
    previousTotal: undefined,
  })

  const [feedbackCounts, setFeedbackCounts] = React.useState<FeedbackCounts>({
    breakdown: { requested: 0, given: 0 },
    currentTotal: 0,
    previousTotal: undefined,
  })

  const [noteCounts, setNoteCounts] = React.useState<NoteCounts>({
    breakdown: { private: 0, visible: 0 },
    currentTotal: 0,
    previousTotal: undefined,
  })

  const [winCounts, setWinCounts] = React.useState<WinCounts>({
    breakdown: { private: 0, visible: 0 },
    currentTotal: 0,
    previousTotal: undefined,
  })

  const loading = React.useMemo(() => {
    return [
      actions.loading,
      checkins.loading,
      feedbackItems.loading,
      feedbackRequests.loading,
      wins.loading,
    ].some(Boolean)
  }, [
    actions.loading,
    checkins.loading,
    feedbackItems.loading,
    feedbackRequests.loading,
    wins.loading,
  ])

  const timeText = vm.timeText(filters)

  const trackFilterChange = (filterName: string, value: string) => {
    trackEvent('$track_org_insights_filter_changed', {
      [filterName]: value,
    })
  }

  return (
    <div className="md:p-10 p-4">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-2">
          <div className="flex flex-wrap gap-3 items-center">
            <div className="px-2 text-gray-600">
              <UsersThree className="h-4 w-4" weight="bold" /> Everyone in{' '}
              {currentUser?.org?.name}
            </div>
            <Select
              isMulti={false}
              onChange={(value: string) => {
                setFilters({ ...filters, time: value as TimeValue })
                trackFilterChange('time', value)
              }}
              options={vm.timeOptions}
              triggerIcon={
                <CalendarBlank
                  className="h-4 text-gray-600 w-4"
                  weight="bold"
                />
              }
              value={filters.time}
            />
          </div>
          {!loading && filters.time !== 'all_time' && (
            <div className="bg-opacity-50 bg-pink-100 px-2 py-1.5 rounded text-gray-900">
              <Sparkle
                className="h-4 mb-1 mr-2 text-pink-600 w-4"
                weight="fill"
              />
              {vm.engagementText(
                actionCounts,
                completedCheckinCount,
                feedbackCounts,
                filters,
                noteCounts,
                totalCheckinCount,
                winCounts
              )}
            </div>
          )}
        </div>
        <UpdatesSection
          actionCounts={actionCounts}
          feedbackCounts={feedbackCounts}
          filters={filters}
          noteCounts={noteCounts}
          setActionCounts={setActionCounts}
          setFeedbackCounts={setFeedbackCounts}
          setNoteCounts={setNoteCounts}
          setWinCounts={setWinCounts}
          timeText={timeText}
          winCounts={winCounts}
        />
        <CheckinsSection
          completedCount={completedCheckinCount}
          filters={filters}
          previousCompletedCount={previousCompletedCheckinCount}
          previousTotalCount={previousTotalCheckinCount}
          setCompletedCount={setCompletedCheckinCount}
          setPreviousCompletedCount={setPreviousCompletedCheckinCount}
          setPreviousTotalCount={setPreviousTotalCheckinCount}
          setTotalCount={setTotalCheckinCount}
          timeText={timeText}
          totalCount={totalCheckinCount}
        />
      </div>
    </div>
  )
})
