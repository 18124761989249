export type ActionCounts = {
  breakdown: {
    completed: number
    created: number
  }
  currentTotal: number
  previousTotal?: number
}

export type FeedbackCounts = {
  breakdown: {
    given: number
    requested: number
  }
  currentTotal: number
  previousTotal?: number
}

export type NoteCounts = {
  breakdown: {
    private: number
    visible: number
  }
  currentTotal: number
  previousTotal?: number
}

export type WinCounts = {
  breakdown: {
    private: number
    visible: number
  }
  currentTotal: number
  previousTotal?: number
}

export type UpdateCountsBreakdown =
  | ActionCounts
  | FeedbackCounts
  | NoteCounts
  | WinCounts

export type InsightsFilters = {
  time?: TimeValue
}

export type TimeValue =
  | 'thirty_days'
  | 'three_months'
  | 'twelve_months'
  | 'all_time'

export type UpdateTypes = 'Actions' | 'Feedback' | 'Notes' | 'Wins'

export const DEFAULT_TIME_VALUE = 'three_months'

export const TIME_VALUES = {
  thirty_days: '30 days',
  three_months: '3 months',
  twelve_months: '12 months',
  all_time: 'All time',
}
