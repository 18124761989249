import * as React from 'react'
import { CheckinSkillInsight, InsightsFilters } from '../insights-skills.types'
import { convertValueToUserIds } from 'components/manager-user-select/utils'
import { CustomRadarDot } from 'components/skills-radar-chart/custom-radar-dot'
import { SkillsRadarChartSeries } from '../../../components/skills-radar-chart/skills-radar-chart.types'
import { User } from 'store/modules/users'

export class CheckinsSectionVm {
  constructor(
    private data: CheckinSkillInsight[],
    private filters: InsightsFilters,
    private user: User
  ) {}

  get levelCounts() {
    return this.data.reduce<{
      exceeding: number
      meeting: number
      working_towards: number
    }>(
      (counts, checkinSkill) => {
        switch (checkinSkill.averageStatus) {
          case 'exceeding':
            counts.exceeding += 1
            break
          case 'meeting':
            counts.meeting += 1
            break
          case 'working_towards':
            counts.working_towards += 1
            break
        }

        return counts
      },
      { exceeding: 0, meeting: 0, working_towards: 0 }
    )
  }

  get levelDescription() {
    return this.reportIds.length > 1 ? 'Average level' : 'Current level'
  }

  get overallStatus() {
    if (this.overallDifference === 0) return 'meeting'
    if (this.overallDifference > 0) return 'exceeding'

    return 'working_towards'
  }

  get reportIds() {
    return convertValueToUserIds(this.user, this.filters.user_id)
  }

  get series() {
    return {
      required: {
        colour: '#CECACA',
        skills: this.requiredSkills,
      },
      reports: {
        colour: '#0D592D',
        dot: (chartData) => {
          const data = chartData.payload
          const payload = data.payload

          let colour = '#D9A817'
          if (payload.reports > payload.required) colour = '#267D50'
          if (payload.reports === payload.required) colour = '#38B776'

          return (
            <CustomRadarDot
              cx={data.x}
              cy={data.y}
              colour={colour}
              key={chartData.key}
            />
          )
        },
        skills: this.reportSkills,
      },
    } satisfies SkillsRadarChartSeries
  }

  get skillImprovement() {
    if (this.data.length === 0) return

    const checkinSkill =
      this.sortedCheckinSkillsByLevel[
        this.sortedCheckinSkillsByLevel.length - 1
      ]

    return {
      level: this.levelToDecimal(1, checkinSkill.averageFinalLevel),
      name: checkinSkill.name,
      range:
        checkinSkill.averageFinalLevel === 0
          ? 1
          : Math.ceil(checkinSkill.averageFinalLevel),
      status: checkinSkill.averageStatus,
    }
  }

  get skillStrength() {
    if (this.data.length === 0) return

    const checkinSkill = this.sortedCheckinSkillsByLevel[0]

    return {
      level: this.levelToDecimal(1, checkinSkill.averageFinalLevel),
      name: checkinSkill.name,
      range: Math.ceil(checkinSkill.averageFinalLevel),
      status: checkinSkill.averageStatus,
    }
  }

  private get overallDifference() {
    const { final, required } = this.data.reduce<{
      final: number
      required: number
    }>(
      (sums, checkinSkill) => {
        sums.final += checkinSkill.averageFinalLevel
        sums.required += checkinSkill.averageRequiredLevel

        return sums
      },
      { final: 0, required: 0 }
    )

    return this.levelToDecimal(2, final - required)
  }

  private get reportSkills() {
    return this.data.map((checkinSkill) => {
      return {
        category: checkinSkill.categoryName,
        level: this.levelToDecimal(2, checkinSkill.averageFinalLevel),
        id: checkinSkill.id,
        name: checkinSkill.name,
      }
    })
  }

  private get requiredSkills() {
    return this.data.map((checkinSkill) => {
      return {
        category: checkinSkill.categoryName,
        level: this.levelToDecimal(2, checkinSkill.averageRequiredLevel),
        id: checkinSkill.id,
        name: checkinSkill.name,
      }
    })
  }

  private get sortedCheckinSkillsByLevel() {
    return this.data.sort((a, b) => b.averageFinalLevel - a.averageFinalLevel)
  }

  private levelToDecimal(decimalPlaces: number, level: number) {
    return Number(level.toFixed(decimalPlaces))
  }
}
