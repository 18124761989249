import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnPinningState,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import * as React from 'react'
import { OrgPeopleData } from './data'
import { User } from 'store/modules/users'
import { observer } from 'mobx-react-lite'
import { OrgPeopleTableCsvData } from '../csv-data'
import { store } from 'store/index'

type OrgPeopleTableContextProps = {
  globalFilter: string
  setGlobalFilter: (value: string) => void
  columnFilters: ColumnFiltersState
  columnPinning: ColumnPinningState
  setColumnFilters: (value: ColumnFiltersState) => void
  // Used internally for tracking events
  columnFiltersObject: Record<string, string>
  data: OrgPeopleData
  table: Table<User>
  getFilterValue: (columnId: string) => string
  /**
   * Whether or not any filters (column or global are active) - useful for showing/hiding a clear filters button
   */
  hasActiveFilters: boolean
  /**
   * Download CSV data
   */
  downloadCsvData: () => void
  loading: boolean
}

const OrgPeopleTableContext =
  React.createContext<OrgPeopleTableContextProps | null>(null)

export const OrgPeopleTableProvider = observer(
  ({
    children,
    loading,
  }: {
    children:
      | React.ReactNode
      | ((ctx: OrgPeopleTableContextProps) => React.ReactNode)
    loading: boolean
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([{ id: 'status', value: '1' }])

    const [columnPinning, setColumnPinning] =
      React.useState<ColumnPinningState>({
        // left: ['name'], // removed this to avoid clashing corner cell with sticky header @todo build a proper solution
        // right: ['actions'], // sticky ppositioning and dropdowns don't mix - commenting out for now
      })

    const orgPeopleData = React.useMemo(() => {
      return new OrgPeopleData()
    }, [])

    const table = useReactTable({
      data: orgPeopleData.data,
      columns: orgPeopleData.columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        pagination: {
          pageSize: 25, // custom default page size
        },
      },
      state: {
        columnFilters,
        columnPinning,
        globalFilter,
        columnVisibility: {
          actions: !!store.nonNullCurrentUser.org?.activeSubscription,
          manager: false,
        },
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onColumnPinningChange: setColumnPinning,
    })

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const getFilterValue = (columnId: string) => {
      return (columnFilters.find((column) => column.id === columnId)?.value ||
        '') as string
    }

    const hasActiveFilters = React.useMemo(() => {
      // Consider status = active to be no filtering if it's the only filter set.
      if (
        columnFilters.length === 1 &&
        columnFilters[0].id === 'status' &&
        columnFilters[0].value === '1'
      ) {
        return false
      }

      return Boolean(
        columnFilters.length > 0 || (globalFilter && globalFilter !== '')
      )
    }, [columnFilters, globalFilter])

    const users = table.getRowModel().rows.map((row) => row.original)

    const csvData = React.useMemo(() => {
      return new OrgPeopleTableCsvData(users)
    }, [users])

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      columnPinning,
      setColumnPinning,
      globalFilter,
      setGlobalFilter,
      table,
      data: orgPeopleData,
      getFilterValue,
      hasActiveFilters,
      downloadCsvData: () => csvData.downloadCsvData(),
      loading,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <OrgPeopleTableContext.Provider value={contextValue}>
        {content}
      </OrgPeopleTableContext.Provider>
    )
  }
)

export const useOrgPeopleTable = () => {
  const context = React.useContext(OrgPeopleTableContext)
  if (context === null) {
    throw new Error(
      'useOrgPeopleTable must be used within a OrgPeopleTableProvider'
    )
  }
  return context
}
