import { Duration, sub } from 'date-fns'
import { isNumber } from '../../utils/number'
import { RequestResult } from '../../api/types'
import { TimeValue } from './insights-engagement-page.types'

export const convertTimeValueToDateString = (value?: TimeValue) => {
  switch (value) {
    case 'thirty_days':
      return isoDateString({ days: 30 })
    case 'three_months':
      return isoDateString({ months: 3 })
    case 'twelve_months':
      return isoDateString({ years: 1 })
    default:
      return undefined
  }
}

export const convertTimeValueToPreviousDateString = (value?: TimeValue) => {
  switch (value) {
    case 'thirty_days':
      return isoDateString({ days: 60 })
    case 'three_months':
      return isoDateString({ months: 6 })
    case 'twelve_months':
      return isoDateString({ years: 2 })
    default:
      return undefined
  }
}

export const count = (response: RequestResult) => {
  const { meta } = response

  return meta?.total && isNumber(meta.total) ? meta.total : 0
}

export const levelToDecimal = (decimalPlaces: number, level: number) => {
  return Number(level.toFixed(decimalPlaces)) || 0
}

const isoDateString = (duration: Duration) => {
  const date = new Date()
  const roundedDate = new Date(Math.round(date.getTime() / 60000) * 60000)
  return sub(roundedDate, duration).toISOString()
}
