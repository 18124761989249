import { trackEvent } from 'app/packs/src/services/event-tracker'
import { SearchFilter } from 'components/search-filter'
import * as React from 'react'
import { useOrgPeopleTable } from '../org-people-table/org-people-table-context'
import { ClearFilterButton } from './clear'
import { ManagerFilter } from './manager'
import { StatusFilter } from './status'
import { TeamFilter } from './team'
import { OrgPeopleStatus } from '../org-people-table/data'

export const OrgPeoplePageFilters = () => {
  const {
    globalFilter,
    setGlobalFilter,
    getFilterValue,
    table,
    hasActiveFilters,
  } = useOrgPeopleTable()

  const onFilterChange = (column: string, value: string | string[]) => {
    // Check if the column is 'status' and the value is '0'
    if (column === 'status' && value === '0') {
      table.getColumn(column)?.setFilterValue('')
    } else {
      table.getColumn(column)?.setFilterValue(value)
    }

    trackEvent('$track_org_people_filter_changed', { [column]: value })
  }

  const clearFilters = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
    // Default to active status.
    table.getColumn('status')?.setFilterValue(OrgPeopleStatus.Active)
  }

  return (
    <div className="flex w-full flex-wrap justify-between items-center gap-3 px-6">
      <div className="flex flex-row gap-2 flex-wrap">
        <SearchFilter
          name="org-people-search"
          onChange={(value) => {
            setGlobalFilter(String(value))
          }}
          value={globalFilter ?? ''}
        />

        <StatusFilter
          onChange={(value) => onFilterChange('status', value)}
          value={getFilterValue('status')}
        />

        <ManagerFilter
          onChange={(value) => onFilterChange('manager', value)}
          value={getFilterValue('manager')}
        />

        <TeamFilter
          onChange={(value) => onFilterChange('team', value)}
          value={getFilterValue('team')}
        />

        {hasActiveFilters && <ClearFilterButton onClick={clearFilters} />}
      </div>
      <div className="text-gray-600">
        Showing{' '}
        <strong>{table.getRowModel().rows.length.toLocaleString()}</strong> of{' '}
        <strong>
          {table.getFilteredRowModel().rows.length.toLocaleString()}
        </strong>{' '}
        people
      </div>
    </div>
  )
}
