import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { Card, Loader } from 'src/design-system'
import { iconMap, MonthlyUpdatesSectionVm } from './monthly-updates-section-vm'
import { UpdateCountsBreakdown } from '../insights-skills.types'

export type MonthlyUpdatesCardProps = {
  counts: UpdateCountsBreakdown
  icon: React.ReactNode
  type: string
  vm: MonthlyUpdatesSectionVm
  loading?: boolean
}

export const MonthlyUpdatesCard = observer((props: MonthlyUpdatesCardProps) => {
  const { type, icon, counts, vm, loading } = props

  const Icon =
    iconMap[vm.compareIconType(counts.previousTotal, counts.currentTotal)]

  return (
    <Card className="m-0 px-5 py-7 flex flex-col items-center gap-y-4 justify-between">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col items-center gap-y-1">
            <div className="flex flex-row text-gray-600 items-center gap-x-1">
              {icon}
              <div>{type}</div>
            </div>
            <div className="text-gray-900 text-title font-bold">
              {counts.currentTotal}
            </div>
          </div>
          <div
            className={cn(
              'flex flex-row items-center gap-x-1',
              vm.compareTextClassName(counts.previousTotal, counts.currentTotal)
            )}
          >
            <Icon aria-hidden weight="bold" />
            <div>
              {vm.comparePercentageText(
                counts.previousTotal,
                counts.currentTotal
              )}
              last 30 days
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-8 h-[41px]">
            {Object.entries(counts.breakdown).map(([breakdownType, count]) => (
              <div
                className={cn('flex flex-col items-center justify-between', {
                  hidden: type === 'Notes',
                })}
                key={breakdownType}
              >
                <div className="text-gray-600 text-xs capitalize">
                  {breakdownType}
                </div>
                <div className="text-gray-900">{count}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  )
})
