export const UpdateColours: Record<string, string> = {
  actions: '#219653',
  empty: '#F2F2F2',
  feedback_received: '#BDA00F',
  notes: '#0D2D59',
  wins: '#57DB8E',
}

export type UpdateCounts = {
  actionsCompletedCount?: number
  feedbackReceivedCount?: number
  notesCount?: number
  winsReceivedCount?: number
}

export type UpdateType =
  | 'actions'
  | 'checkins'
  | 'feedback_received'
  | 'feedback_requested'
  | 'focus_skills'
  | 'notes'
  | 'promotions'
  | 'wins'

export type SearchUpdateType = 'relevant_updates' | 'all_updates'
