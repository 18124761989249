import * as React from 'react'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { CheckinsSectionVm } from '../checkins-section-vm'
import { StrengthAndImprovementCard } from './strength-and-improvement-card'

export type StrengthsAndImprovementsSectionProps = {
  count: number
  loading: boolean
  selectedUserText: string
  vm: CheckinsSectionVm
}

export const StrengthsAndImprovementsSection = (
  props: StrengthsAndImprovementsSectionProps
) => {
  const { count, loading, selectedUserText, vm } = props

  return (
    <>
      <div className="flex flex-col mb-5">
        <div className="flex flex-col md:flex-row justify-center md:justify-start gap-x-2 md:items-center mb-3">
          <p className="font-bold mb-0 text-xl">
            Strengths and areas to improve
          </p>
          <p className="mb-0 text-gray-600">{selectedUserText}</p>
        </div>
        <div className="flex gap-2 items-center">
          {count > 0 ? (
            <>
              <p className="mb-0 text-gray-600">Currently</p>
              <CheckinStatusLabel status={vm.overallStatus} />
              <p className="mb-0 text-gray-600">required skills</p>
            </>
          ) : (
            <p className="mb-0 text-gray-600">
              There must be at least 1 completed Check-in to see this data
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-24">
        <StrengthAndImprovementCard
          levelDescription={vm.levelDescription}
          loading={loading}
          skill={vm.skillStrength}
          subtitle="Strength"
        />
        <StrengthAndImprovementCard
          levelDescription={vm.levelDescription}
          loading={loading}
          skill={vm.skillImprovement}
          subtitle="Room for improvement"
        />
      </div>
    </>
  )
}
