import { Card, Loader } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { ComparisonDifferenceText } from 'components/comparison-difference-text'
import { UpdateCountsBreakdown } from '../insights-engagement-page.types'

export type UpdatesCardProps = {
  counts: UpdateCountsBreakdown
  icon: React.ReactNode
  timePeriodText?: string
  type: string
  loading?: boolean
}

export const UpdatesCard = observer((props: UpdatesCardProps) => {
  const { counts, icon, timePeriodText, type, loading } = props

  return (
    <Card className="m-0 px-5 py-7 flex flex-col items-center gap-y-4 justify-between">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col items-center gap-y-1">
            <div className="flex flex-row text-gray-600 items-center gap-x-1">
              {icon}
              <div>{type}</div>
            </div>
            <div className="text-gray-900 text-title font-bold">
              {counts.currentTotal}
            </div>
          </div>
          <ComparisonDifferenceText
            currentValue={counts.currentTotal}
            previousValue={counts.previousTotal}
            timePeriodText={timePeriodText}
          />
          <div className="flex flex-row items-center gap-x-8 h-[41px]">
            {Object.entries(counts.breakdown).map(([breakdownType, count]) => (
              <div
                className={cn('flex flex-col items-center justify-between')}
                key={breakdownType}
              >
                <div className="text-gray-600 text-xs capitalize">
                  {breakdownType}
                </div>
                <div className="text-gray-900">{count}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  )
})
