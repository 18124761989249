import { AvatarButtonGroup } from 'src/design-system'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { Select } from 'components/atoms/select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'

export type ManagerFilterProps = {
  onChange: (value: string) => void
  value: string
}

export const ManagerFilter = observer((props: ManagerFilterProps) => {
  const { onChange, value } = props

  const defaultLabel = 'All managers'

  const managers = store.users.managers.sort((a, b) =>
    a.fullName.localeCompare(b.fullName)
  )

  const ManagerOptions = [
    { label: defaultLabel, value: '' },
    ...managers.map((manager) => ({
      label: manager.fullName,
      value: manager.fullName,
    })),
  ]

  return (
    <Select
      isMulti={false}
      onChange={onChange}
      options={ManagerOptions}
      triggerClassName="max-h-[34px]"
      triggerContent={
        <div className="flex gap-x-2.5 items-center">
          {managers.length > 0 && (
            <AvatarButtonGroup
              avatars={avatarGroupProps(managers, {
                href: true,
                stateTooltip: true,
              })}
              size="xs"
              limitStyle="avatar"
              limit={2}
            />
          )}
          <span>{defaultLabel}</span>
        </div>
      }
      value={value}
    />
  )
})
