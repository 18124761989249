import { Select } from 'components/atoms/select'
import { UsersThree } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'

export type TeamFilterProps = {
  onChange: (value: string) => void
  value: string
}

export const TeamFilter = (props: TeamFilterProps) => {
  const { onChange, value } = props

  const TeamOptions = [
    { label: 'All teams', value: '' },
    ...store.teams.all.map((team) => ({
      label: team.name,
      value: team.name,
    })),
  ]

  return (
    <Select
      isMulti={false}
      onChange={onChange}
      options={TeamOptions}
      triggerContent={
        <div className="flex gap-1 items-center">
          <UsersThree
            aria-hidden
            className="h-4 text-gray-600 w-4"
            weight="bold"
          />
          All teams
        </div>
      }
      value={value}
    />
  )
}
