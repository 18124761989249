import { useModalContext } from 'src/design-system'
import * as React from 'react'
import { INVITE_MODAL_ID } from 'components/invite-modal/utils'
import { InviteModalProps } from 'components/invite-modal'
import { OrgPeoplePageFilters } from './filters'
import { OrgPeoplePageHeader } from './header'
import { OrgPeopleTable } from './org-people-table'
import { OrgPeopleTablePagination } from './pagination'
import { OrgPeopleTableProvider } from './org-people-table/org-people-table-context'
import { removeUrlParams } from '../../utils/url-helpers'
import { store } from 'store/index'

export const OrgPeoplePage = () => {
  const { openModal } = useModalContext()
  const [loading, setLoading] = React.useState(true)
  const [updateFlag, setUpdateFlag] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('invite')) {
        openModal<InviteModalProps>(INVITE_MODAL_ID, {
          intent: 'org-people-page',
        })
        removeUrlParams('invite')
      }

      await store.users.fetchAll({
        include: ['team', 'position'],
        page: { size: 25 },
      })
      setLoading(false)
    }

    fetchData()
  }, [openModal])

  React.useEffect(() => {
    const handleReload = () => {
      const fetchData = async () => {
        await store.users.fetchAll(
          {
            include: ['team', 'position'],
            page: { size: 25 },
          },
          { bypassCache: true }
        )
        setUpdateFlag((prev) => !prev) // force re-render.
        setLoading(false)
      }
      fetchData()
    }

    window.addEventListener('userInfoUpdated', handleReload)

    return () => {
      window.removeEventListener('userInfoUpdated', handleReload)
    }
  }, [])

  return (
    <OrgPeopleTableProvider loading={loading}>
      <main className="relative w-full h-screen flex flex-col">
        <div className="w-full flex flex-col gap-3 pb-3">
          <OrgPeoplePageHeader />
          <OrgPeoplePageFilters />
        </div>
        <OrgPeopleTable />
        <OrgPeopleTablePagination />
      </main>
    </OrgPeopleTableProvider>
  )
}
