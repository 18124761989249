import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Tag, Tooltip } from 'src/design-system'
import { User } from 'store/modules/users'

export const RoleCell = observer((data: CellContext<User, unknown>) => {
  const user = data.row.original

  const roleText = {
    admin: 'Admin',
    member: user.editableTeams.length > 0 ? 'Editor' : 'Member',
  }[user.orgRole]

  const editableTeamNames = user.editableTeams
    .map((team) => team.name)
    .join(', ')

  return (
    <div className="flex flex-row items-center gap-x-1">
      {roleText}
      {user.editableTeams.length > 0 && user.isMember && (
        <Tooltip content={editableTeamNames} className="mt-0.5">
          <Tag variant="paper">{user.editableTeams.length}</Tag>
        </Tooltip>
      )}
    </div>
  )
})
