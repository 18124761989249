import { Card, Loader } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckinProgressIndicator } from 'components/checkin-progress-indicator'
import {
  CheckinStatus,
  CheckinStatusLabel,
} from 'components/checkin-status-label'

export type StrengthAndImprovementCardProps = {
  levelDescription: string
  skill?: {
    level: number
    name: string
    range: number
    status: CheckinStatus
  }
  subtitle: string
  loading?: boolean
}

export const StrengthAndImprovementCard = observer(
  (props: StrengthAndImprovementCardProps) => {
    const { levelDescription, skill, subtitle, loading } = props

    const skillRange = skill?.range || 5

    return (
      <Card className="flex flex-col items-center m-0 px-5 py-10">
        {loading ? (
          <Loader />
        ) : (
          <>
            <p className="mb-0 text-gray-600 text-xs">{subtitle}</p>
            <h2 className="font-bold mb-2 text-2xl text-center text-gray-900 truncate w-full">
              {skill?.name || 'No Check-in data'}
            </h2>
            <CheckinStatusLabel status={skill?.status || 'not_checked_in'} />
            <p className="mb-8 mt-7 text-gray-600 text-xs">
              {levelDescription}
            </p>
            <div className="flex gap-2 items-center w-[194px]">
              <span className="text-gray-600 text-xs">0</span>
              <div className="flex gap-0.5 items-center w-full">
                {[...Array(skillRange)].map((_, index) => {
                  const value =
                    skill && index + 1 === skillRange
                      ? (skill.level - skillRange + 1) * 100
                      : 100

                  return (
                    <CheckinProgressIndicator
                      className="h-2"
                      key={index}
                      status={skill?.status || 'empty'}
                      value={value}
                    />
                  )
                })}
              </div>
              <span className="text-gray-600 text-xs">{skillRange}</span>
            </div>
          </>
        )}
      </Card>
    )
  }
)
