import { Checks, CircleWavyCheck, Megaphone, Note } from '@phosphor-icons/react'
import React from 'react'
import { ActionFilters } from 'store/modules/actions'
import {
  convertTimeValueToDateString,
  convertTimeValueToPreviousDateString,
  count,
} from '../utils'
import { FeedbackItemFilters } from 'store/modules/feedback-item'
import { FeedbackRequestFilters } from 'store/modules/feedback-request'
import { InsightsFilters, UpdateTypes } from '../insights-engagement-page.types'
import { isNumber } from 'app/packs/src/utils/number'
import { RequestResult } from 'app/packs/src/api/types'
import { store } from 'store/index'
import { WinFilters } from 'store/modules/wins'

export class UpdatesSectionVm {
  constructor() {}
  get orgWinsUrl() {
    return store.currentUser
      ? `/users/${store.currentUser.slug}/wins/org`
      : undefined
  }

  get updateTypes(): { icon: React.ReactNode; type: UpdateTypes }[] {
    return [
      {
        icon: <CircleWavyCheck className="text-green-400" weight="bold" />,
        type: 'Wins',
      },
      {
        icon: <Checks className="text-green-600" weight="bold" />,
        type: 'Actions',
      },
      {
        icon: <Megaphone className="text-yellow-600" weight="bold" />,
        type: 'Feedback',
      },
      {
        icon: <Note className="text-blue-600" weight="bold" />,
        type: 'Notes',
      },
    ]
  }

  // Actions
  async fetchActionCounts(filters: InsightsFilters) {
    const completed = await this.fetchCurrentCompletedActionCount(filters)
    const created = await this.fetchCurrentCreatedActionCount(filters)

    const previousTotal =
      filters.time === 'all_time'
        ? undefined
        : (await this.fetchPreviousCreatedActionCount(filters)) +
          (await this.fetchPreviousCompletedActionCount(filters))

    return {
      breakdown: { completed, created },
      currentTotal: completed + created,
      previousTotal,
    }
  }

  private async fetchCurrentCreatedActionCount(filters: InsightsFilters) {
    return this.fetchCounts('actions', {
      created_at_from: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchCurrentCompletedActionCount(filters: InsightsFilters) {
    return this.fetchCounts('actions', {
      completed_at_from: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchPreviousCreatedActionCount(filters: InsightsFilters) {
    return this.fetchCounts('actions', {
      created_at_from: convertTimeValueToPreviousDateString(filters.time),
      created_at_to: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchPreviousCompletedActionCount(filters: InsightsFilters) {
    return this.fetchCounts('actions', {
      completed_at_from: convertTimeValueToPreviousDateString(filters.time),
      completed_at_to: convertTimeValueToDateString(filters.time),
    })
  }

  // Feedback
  async fetchFeedbackCounts(filters: InsightsFilters) {
    const given = await this.fetchCurrentFeedbackItemCount(filters)
    const requested = await this.fetchCurrentFeedbackRequestCount(filters)

    const previousTotal =
      filters.time === 'all_time'
        ? undefined
        : (await this.fetchPreviousFeedbackItemCount(filters)) +
          (await this.fetchPreviousFeedbackRequestCount(filters))

    return {
      breakdown: { given, requested },
      currentTotal: given + requested,
      previousTotal,
    }
  }

  private async fetchCurrentFeedbackItemCount(filters: InsightsFilters) {
    return this.fetchCounts('feedbackItems', {
      created_at_from: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchCurrentFeedbackRequestCount(filters: InsightsFilters) {
    return this.fetchCounts('feedbackRequests', {
      created_at_from: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchPreviousFeedbackItemCount(filters: InsightsFilters) {
    return this.fetchCounts('feedbackItems', {
      created_at_from: convertTimeValueToPreviousDateString(filters.time),
      created_at_to: convertTimeValueToDateString(filters.time),
    })
  }

  private async fetchPreviousFeedbackRequestCount(filters: InsightsFilters) {
    return this.fetchCounts('feedbackRequests', {
      created_at_from: convertTimeValueToPreviousDateString(filters.time),
      created_at_to: convertTimeValueToDateString(filters.time),
    })
  }

  // Notes/Wins
  async fetchNoteCounts(filters: InsightsFilters) {
    const currentCounts = await this.fetchCurrentWinCounts(filters, 'Note')
    const previousCounts = await this.fetchPreviousWinCounts(filters, 'Note')

    return {
      breakdown: currentCounts,
      currentTotal: currentCounts.private + currentCounts.visible,
      previousTotal: previousCounts
        ? previousCounts.private + previousCounts.visible
        : previousCounts,
    }
  }

  async fetchWinCounts(filters: InsightsFilters) {
    const currentCounts = await this.fetchCurrentWinCounts(filters, 'Win')
    const previousCounts = await this.fetchPreviousWinCounts(filters, 'Win')

    return {
      breakdown: currentCounts,
      currentTotal: currentCounts.private + currentCounts.visible,
      previousTotal: previousCounts
        ? previousCounts.private + previousCounts.visible
        : previousCounts,
    }
  }

  private async fetchCurrentWinCounts(
    filters: InsightsFilters,
    type: 'Note' | 'Win'
  ) {
    return this.fetchCountsForWins({
      private_count: true,
      took_place_on_from: convertTimeValueToDateString(filters.time),
      win_category_title: type,
    })
  }

  private async fetchPreviousWinCounts(
    filters: InsightsFilters,
    type: 'Note' | 'Win'
  ) {
    if (filters.time === 'all_time') return undefined

    return this.fetchCountsForWins({
      private_count: true,
      took_place_on_from: convertTimeValueToPreviousDateString(filters.time),
      took_place_on_to: convertTimeValueToDateString(filters.time),
      win_category_title: type,
    })
  }

  private async fetchCounts(
    type: 'actions' | 'feedbackItems' | 'feedbackRequests',
    filter: ActionFilters | FeedbackItemFilters | FeedbackRequestFilters
  ) {
    const page = { size: 0 }

    let response: RequestResult

    switch (type) {
      case 'actions':
        response = await store.actions.fetchAll({ filter, page })
        break
      case 'feedbackItems':
        response = await store.feedbackItems.fetchAll({ filter, page })
        break
      case 'feedbackRequests':
        response = await store.feedbackRequests.fetchAll({ filter, page })
        break
      default:
        response = { meta: { total: 0 }, success: true }
    }

    return count(response)
  }

  private async fetchCountsForWins(filter: WinFilters) {
    const breakdown = { private: 0, visible: 0 }

    const { meta } = await store.wins.fetchAll({ filter, page: { size: 0 } })

    if (meta?.total && isNumber(meta.total)) breakdown.visible = meta.total

    if (meta?.private_count && isNumber(meta.private_count)) {
      breakdown.private = meta.private_count
    }

    return breakdown
  }
}
