import pluralize from 'pluralize'
import { CheckinSkillInsight } from '../insights-skills.types'
import { CheckinStatus } from 'components/checkin-status-label'
import { levelStatus } from 'app/packs/src/utils/checkin-helpers'

export class RadarChartHoverCardVm {
  constructor(private data: CheckinSkillInsight[], private skillId: string) {}

  get numberOfCheckinText() {
    const count = this.checkinSkill?.userCount || 0

    return `${count} ${pluralize('Check-in', count)}`
  }

  get users() {
    return this.checkinSkill?.users || []
  }

  blobColour(status: CheckinStatus) {
    return `${status.replaceAll('_', '-')}`
  }

  status(level: number, requiredLevel: number) {
    return levelStatus(level, requiredLevel)
  }

  private get checkinSkill() {
    return this.data.find((checkinSkill) => checkinSkill.id === this.skillId)
  }
}
