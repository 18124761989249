import { User } from 'store/modules/users'
import { trackEvent } from '../../services/event-tracker'
import { downloadLink } from '../../utils/file-helpers'

export class OrgPeopleTableCsvData {
  constructor(private users: User[]) {}

  downloadCsvData() {
    trackEvent('$track_download_filtered_org_people_clicked')
    const csvContent = `data:text/csv;charset=utf-8,${this.csvData()}`
    const csvFilename = `org-people-${new Date().toLocaleDateString()}.csv`
    downloadLink(csvContent, csvFilename)
  }

  private csvData() {
    const data: string[] = []

    data.push(
      'email,fname,lname,role,position,team,account_active,last_sign_in,manager_fname,manager_lname,manager_email,reports'
    )

    this.users.forEach((user) => {
      const roleText = {
        admin: 'Admin',
        member: user.editableTeams.length > 0 ? 'Editor' : 'Member',
      }[user.orgRole]

      const formatReportName = (user: User) => {
        let name = user.fullName
        if (user.lname) name += ` (${user.email})`
        return name
      }

      const reportsText =
        user.directReports.length > 0
          ? user.directReports.map(formatReportName).join(', ')
          : '-'

      data.push(
        [
          user.email,
          user.fname,
          user.lname || '-',
          roleText,
          user.position?.name || '-',
          user.team?.name || '-',
          user.status,
          user.currentSignInAtWords,
          user.manager ? user.manager.fname : '-',
          user.manager ? user.manager.lname : '-',
          user.manager ? user.manager.email : '-',
          `"${reportsText}"`,
        ].join(',')
      )
    })

    return data.join('%0A')
  }
}
