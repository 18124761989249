import { ModalButton } from 'src/design-system'
import { ArrowSquareOut } from '@phosphor-icons/react'
import * as React from 'react'
import { INVITE_MODAL_ID } from './utils'

export type InviteModalButtonProps = {
  intent: string
}

export const InviteModalButton = (props: InviteModalButtonProps) => {
  const { intent } = props

  return (
    <ModalButton
      modalId={INVITE_MODAL_ID}
      variant="default"
      className="items-center"
      size="nav"
      fullWidth={true}
      modalProps={{ intent }}
    >
      <ArrowSquareOut /> Invite
    </ModalButton>
  )
}
