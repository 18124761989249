import { observer } from 'mobx-react-lite'
import { Tooltip } from 'recharts'
import * as React from 'react'
import { CheckinChartsEmptyStateBanner } from './checkin-charts-empty-state-banner'
import { CheckinSkillInsight, InsightsFilters } from '../insights-skills.types'
import { CheckinsSectionVm } from './checkins-section-vm'
import { deserialize } from 'app/packs/src/api/utils/deserialize'
import { RadarChartHoverCard } from '../radar-chart-hover-card'
import { SkillChartsLegend } from 'components/skill-charts-legend'
import { SkillRatioDonutChart } from 'components/skill-ratio-donut-chart'
import { SkillsRadarChart } from 'components/skills-radar-chart'
import { StrengthsAndImprovementsSection } from './strengths-and-improvements-section'
import { User } from 'store/modules/users'

type CheckinsSectionProps = {
  filters: InsightsFilters
  selectedUserText: string
  user: User
}

export const CheckinsSection = observer((props: CheckinsSectionProps) => {
  const { filters, selectedUserText, user } = props

  const [data, setData] = React.useState<CheckinSkillInsight[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await fetchCheckinSkillInsights(filters.user_id)
      setData(data)
      setLoading(false)
    }

    fetchData()
  }, [filters.user_id])

  const vm = React.useMemo(() => {
    return new CheckinsSectionVm(data, filters, user)
  }, [data, filters, user])

  return (
    <>
      <div className="flex flex-col md:flex-row gap-y-4 items-center justify-center mb-8 gap-x-28 mt-10">
        <div className="flex flex-col items-center">
          <h3 className="font-bold text-gray-900 text-xl">Skill map</h3>
          <p className="mb-5 text-gray-600 text-xs">
            Current level vs required
          </p>
          <div className="relative">
            <SkillsRadarChart loading={loading} series={vm.series} size={300}>
              <Tooltip
                content={<RadarChartHoverCard data={data} />}
                cursor={{ fill: 'transparent' }}
                position={{ x: -10, y: 280 }}
                wrapperStyle={{ zIndex: 10 }}
              />
            </SkillsRadarChart>
            {!loading && data.length === 0 && <CheckinChartsEmptyStateBanner />}
          </div>
        </div>
        <SkillRatioDonutChart
          counts={vm.levelCounts}
          emptyState={<CheckinChartsEmptyStateBanner />}
          loading={loading}
        />
      </div>
      <div className="mb-24">
        <SkillChartsLegend
          noOfUsers={vm.reportIds.length}
          title={selectedUserText}
        />
      </div>
      <StrengthsAndImprovementsSection
        count={data.length}
        loading={loading}
        selectedUserText={selectedUserText}
        vm={vm}
      />
    </>
  )
})

async function fetchCheckinSkillInsights(userId?: string) {
  const response = await fetch(
    `/api/v1/insights/checkin_skills?filter[user_id]=${userId}`
  )

  if (!response.ok) return []

  const json = await response.json()
  const deserialized = deserialize(json)
  if (!deserialized?.data) return []

  const checkinSkillInsights = deserialized.data.checkinSkillInsights as Record<
    string,
    CheckinSkillInsight
  >

  if (!checkinSkillInsights) return []

  return Object.values(checkinSkillInsights)
}
