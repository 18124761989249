import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { BaseAxisProps } from 'recharts/types/util/types'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import * as React from 'react'
import { dayFromDate } from 'app/packs/src/utils/date-helpers'
import { EmptyUpdateHoverCard } from 'components/empty-update-hover-card'
import { UpdateColours, UpdateType } from 'app/packs/src/types/updates'
import { UpdateHoverCard } from 'components/update-hover-card'

type GraphData = {
  actions?: number
  feedback?: number
  fillOpacity: number
  key: string
  notes?: number
  wins?: number
}

export type DataCollectedOverTimeChartProps = {
  additionalTooltipInformation?: React.ReactNode
  barKeys: (UpdateType | 'empty')[]
  data: GraphData[]
  isEmpty?: boolean
  onMouseEnter?: (payload: { key: string }) => void
  onMouseOut?: () => void
}

export const DataCollectedOverTimeUpdateTypes: UpdateType[] = [
  'actions',
  'feedback_received',
  'notes',
  'wins',
]

export const DataCollectedOverTimeChart: React.VFC<
  DataCollectedOverTimeChartProps
> = (props) => {
  const {
    additionalTooltipInformation,
    barKeys,
    data,
    isEmpty,
    onMouseEnter,
    onMouseOut,
  } = props

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart
        data={data}
        margin={{
          bottom: 5,
          left: 20,
          right: 30,
          top: 20,
        }}
      >
        <XAxis
          allowDataOverflow={true}
          axisLine={false}
          dataKey="key"
          tick={CustomizedXAxisTick}
          tickFormatter={(value: string) => dayFromDate(value)}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          fontSize={12}
          tickCount={2}
          tickLine={false}
          width={5}
        />
        <Tooltip
          content={
            <CustomToolTip
              additionalInformation={additionalTooltipInformation}
              isEmpty={isEmpty}
              updateTypes={DataCollectedOverTimeUpdateTypes}
            />
          }
          cursor={{ fill: 'transparent' }}
          wrapperStyle={{ zIndex: 20 }}
        />

        {barKeys.map((barKey) => (
          <Bar
            dataKey={barKey}
            fill={UpdateColours[barKey]}
            isAnimationActive={true}
            key={barKey}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            stackId="a"
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomToolTip = (
  props: TooltipProps<ValueType, NameType> & {
    additionalInformation?: React.ReactNode
    isEmpty?: boolean
    updateTypes: UpdateType[]
  }
) => {
  const { additionalInformation, isEmpty, payload, updateTypes } = props

  const chartData = payload?.[0]

  if (!chartData) return null

  if (isEmpty) return <EmptyUpdateHoverCard />

  return (
    <UpdateHoverCard
      additionalInformation={additionalInformation}
      includedUpdateTypes={updateTypes}
      updateCounts={{
        actionsCompletedCount: chartData.payload.actions,
        feedbackReceivedCount: chartData.payload.feedback_received,
        notesCount: chartData.payload.notes,
        winsReceivedCount: chartData.payload.wins,
      }}
    />
  )
}

const CustomizedXAxisTick: BaseAxisProps['tick'] = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${(y as number) + 18})`}>
      <text
        className="fill-current text-gray-600 text-xs"
        textAnchor="middle"
        x={0}
        y={0}
      >
        {dayFromDate(payload.value)}
      </text>
    </g>
  )
}
