import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { User } from 'store/modules/users'
import { observer } from 'mobx-react-lite'

export const StatusCell = observer((data: CellContext<User, unknown>) => {
  const user = data.row.original

  if (user.status === 'Archived')
    return <span className="text-gray-600">Archived</span>

  return (
    <div className="flex flex-col">
      {user.status}
      <span className="text-gray-300 text-xs">{user.currentSignInAtWords}</span>
    </div>
  )
})
