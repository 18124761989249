import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { User } from 'store/modules/users'
import { AvatarButtonGroup } from 'src/design-system'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'

export const ReportsCell = (data: CellContext<User, unknown>) => {
  const user = data.row.original

  if (user.directReports.length === 0)
    return <span className="text-gray-600">0</span>

  return (
    <div className="flex flex-row gap-x-3 items-center whitespace-nowrap">
      <span className="sr-only">{user.directReports.length}</span>
      <AvatarButtonGroup
        avatars={avatarGroupProps(user.directReports, {
          href: true,
          stateTooltip: true,
        })}
        size="xs"
        limit={3}
      />
    </div>
  )
}
