import { add, sub } from 'date-fns'
import { currentUrl } from 'app/packs/src/utils/url-helpers'
import { InsightsFilters } from './insights-skills.types'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { User } from 'store/modules/users'

export class InsightsSkillsPageVm {
  constructor() {}

  get canAccessInsights() {
    return store.featureGates.canAccess('insights')
  }

  get defaultFilters() {
    const filters: InsightsFilters = {}
    const searchParams = currentUrl().searchParams

    const userId = searchParams.get('user_id')
    if (userId) filters.user_id = userId
    else if (store.nonNullCurrentUser.isAdmin) filters.user_id = 'everybody'

    return filters
  }

  applyFilters(filters: InsightsFilters) {
    trackEvent('$track_reports_insights_nav_filtered', filters)
    window.history.replaceState(window.history.state, '', this.url(filters))
  }

  async fetchCompletedActions(user: User, number?: number) {
    const { meta } = await store.actions.fetchAll({
      filter: {
        completed_at_from: this.sixtyDaysAgo,
        user_id: this.userIds(user),
      },
      include: ['skills', 'user'],
      page: { number, size: 1000 },
    })

    return meta
  }

  async fetchCreatedActions(user: User, number?: number) {
    const { meta } = await store.actions.fetchAll({
      filter: {
        created_at_from: this.sixtyDaysAgo,
        user_id: this.userIds(user),
      },
      include: ['skills', 'user'],
      page: { number, size: 1000 },
    })

    return meta
  }

  async fetchFeedbackItems(user: User, number?: number) {
    const { meta } = await store.feedbackItems.fetchAll({
      filter: {
        created_at_from: this.sixtyDaysAgo,
        receiver_or_author_id: this.userIds(user),
      },
      include: ['author', 'receiver'],
      page: { number, size: 1000 },
    })

    return meta
  }

  async fetchWins(user: User, number?: number) {
    const { meta } = await store.wins.fetchAll({
      filter: {
        took_place_on_from: this.sixtyDaysAgo,
        winner_or_reporter_id: this.userIds(user),
      },
      include: ['reporter', 'win_category', 'winners'],
      page: { number, size: 1000 },
    })

    return meta
  }

  selectedUserText(filters: InsightsFilters) {
    switch (filters.user_id) {
      case 'everybody':
        return 'Everybody'
      case 'all_reporting_line':
        return 'All reporting line'
      case 'direct_reports':
        return 'Direct reports'
      case 'you_and_all_reporting_line':
      case undefined:
        return 'You and all reporting line'
      case 'you_and_direct_reports':
        return 'You and direct reports'
      default:
        return this.userWithPositionText(filters.user_id)
    }
  }

  private get sixtyDaysAgo() {
    return add(sub(new Date(), { days: 60 }), {
      days: 1,
    }).toISOString()
  }

  private url(filters: InsightsFilters) {
    const url = new URL(window.location.origin + window.location.pathname)

    if (filters.user_id) {
      url.searchParams.append('user_id', filters.user_id)
    }

    return url
  }

  private userIds(user: User) {
    const users = user.isAdmin
      ? store.users.all
      : [user].concat(user.allReports)
    return users.filter((u) => u.hasPackage('grow')).map((report) => report.id)
  }

  private userWithPositionText(userId?: string) {
    if (!userId) return ''

    const user = store.users.byId(userId)
    if (!user) return ''

    let string = user.fullName
    if (user.position) string += ` (${user.position.name})`

    return string
  }
}
