import { createColumnHelper } from '@tanstack/react-table'
import {
  ActionsCell,
  NameCell,
  PositionCell,
  ReportsCell,
  RoleCell,
  StatusCell,
  TeamCell,
} from './cells'
import { User } from 'store/modules/users'
import { store } from 'store/index'

export enum OrgPeopleStatus {
  Active = '1',
  Archived = '-1',
  All = '0',
}

export class OrgPeopleData {
  get data(): User[] {
    return store.users.all
  }

  columnHelper = createColumnHelper<User>()

  get columns() {
    return [
      this.columnHelper.accessor((row) => row.fullName, {
        header: 'Name',
        cell: NameCell,
        id: 'name',
      }),
      this.columnHelper.accessor('orgRole', {
        sortingFn: 'text',
        header: 'Role',
        cell: RoleCell,
        enableGlobalFilter: false,
        id: 'role',
      }),
      this.columnHelper.accessor('team.name', {
        sortingFn: 'text',
        header: 'Team',
        cell: TeamCell,
        id: 'team',
      }),
      this.columnHelper.accessor('position.name', {
        sortingFn: 'text',
        header: 'Position',
        cell: PositionCell,
        id: 'position',
      }),
      this.columnHelper.accessor(
        (row) => {
          return row.isActive
            ? OrgPeopleStatus.Active
            : OrgPeopleStatus.Archived
        },
        {
          header: 'Status',
          cell: StatusCell,
          enableGlobalFilter: false,
          filterFn: 'equalsString',
          id: 'status',
          sortingFn: 'textCaseSensitive',
        }
      ),
      this.columnHelper.accessor((row) => row.directReports.length, {
        header: 'Reports',
        cell: ReportsCell,
        enableGlobalFilter: false,
        id: 'reports',
      }),
      this.columnHelper.accessor('manager.fullName', {
        header: 'Manager',
        enableGlobalFilter: false,
        id: 'manager',
      }),
      this.columnHelper.display({
        header: 'Actions',
        cell: ActionsCell,
        id: 'actions',
      }),
    ]
  }
}
