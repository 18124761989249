import * as React from 'react'
import { CellContext } from '@tanstack/react-table'
import { User } from 'store/modules/users'

export const TeamCell = (data: CellContext<User, unknown>) => {
  const user = data.row.original

  if (!user.team) return <span className="text-gray-300">No team</span>

  return (
    <div className="flex flex-row gap-x-3 items-center whitespace-nowrap">
      {user.team.name}
    </div>
  )
}
