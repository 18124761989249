import { Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckinOverviewCards } from 'components/checkin-overview-cards'
import { CheckinsSectionVm } from './checkins-section-vm'
import { InsightsFilters } from '../insights-engagement-page.types'

type CheckinsSectionProps = {
  completedCount: number
  filters: InsightsFilters
  previousCompletedCount?: number
  previousTotalCount?: number
  setCompletedCount: (count: number) => void
  setPreviousCompletedCount: (count?: number) => void
  setPreviousTotalCount: (count?: number) => void
  setTotalCount: (count: number) => void
  timeText?: string
  totalCount: number
}

export const CheckinsSection = observer((props: CheckinsSectionProps) => {
  const {
    completedCount,
    filters,
    previousCompletedCount,
    previousTotalCount,
    setCompletedCount,
    setPreviousCompletedCount,
    setPreviousTotalCount,
    setTotalCount,
    timeText,
    totalCount,
  } = props

  const [loading, setLoading] = React.useState(true)

  const vm = React.useMemo(() => {
    return new CheckinsSectionVm()
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchCompleted = async () => {
        const count = await vm.fetchCurrentCount(filters, 'finalised')
        setCompletedCount(count)
      }

      const fetchPreviousCompleted = async () => {
        const count = await vm.fetchPreviousCount(filters, 'finalised')
        setPreviousCompletedCount(count)
      }

      const fetchPreviousTotal = async () => {
        const count = await vm.fetchPreviousCount(filters)
        setPreviousTotalCount(count)
      }

      const fetchTotal = async () => {
        const count = await vm.fetchCurrentCount(filters)
        setTotalCount(count)
      }

      setLoading(true)

      await Promise.all([
        fetchCompleted(),
        fetchPreviousCompleted(),
        fetchPreviousTotal(),
        fetchTotal(),
      ])

      setLoading(false)
    }

    fetchData()
  }, [
    filters,
    setCompletedCount,
    setPreviousCompletedCount,
    setPreviousTotalCount,
    setTotalCount,
    vm,
  ])

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-wrap gap-x-2 items-baseline">
        <p className="font-bold mb-0 text-xl">Check-ins</p>
        {timeText && <p className="mb-0 text-gray-600 text-sm">{timeText}</p>}
      </div>
      <CheckinOverviewCards
        completedCheckinCount={completedCount}
        previousCompletedCheckinCount={previousCompletedCount}
        previousTotalCheckinCount={previousTotalCount}
        timePeriodText={timeText}
        totalCheckinCount={totalCount}
        loading={loading}
      />
      {vm.orgCheckinsUrl && (
        <Link
          className="text-center"
          href={vm.orgCheckinsUrl}
          underline={false}
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          View all Check-ins
        </Link>
      )}
    </div>
  )
})
