export class CheckinOverviewCardsVm {
  constructor(
    private completedCheckinCount: number,
    private totalCheckinCount: number,
    private previousCompletedCheckinCount?: number,
    private previousTotalCheckinCount?: number
  ) {}

  get percentageText() {
    return this.totalCheckinCount === 0
      ? '-'
      : `${this.startedVsCompletedPercentage.toFixed(1)}%`
  }

  get previousStartedVsCompletedPercentage() {
    if (!this.previousCompletedCheckinCount || !this.previousTotalCheckinCount)
      return undefined

    return this.percentage(
      this.previousCompletedCheckinCount,
      this.previousTotalCheckinCount
    )
  }

  get startedVsCompletedPercentage() {
    return this.percentage(this.completedCheckinCount, this.totalCheckinCount)
  }

  private percentage(value: number, total: number) {
    return total === 0 ? 0 : (value / total) * 100
  }
}
