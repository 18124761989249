import cn from 'classnames'
import * as React from 'react'
import { className, countText, iconMap, iconType } from './utils'

type ComparisonDifferenceTextProps = {
  currentValue: number
  previousValue?: number
  timePeriodText?: string
}

export const ComparisonDifferenceText = (
  props: ComparisonDifferenceTextProps
) => {
  const { currentValue, previousValue, timePeriodText } = props

  if (previousValue === undefined) return null

  const Icon = iconMap[iconType(currentValue, previousValue)]

  return (
    <div
      className={cn(
        'flex flex-row gap-x-1 items-center',
        className(currentValue, previousValue)
      )}
    >
      <Icon aria-hidden weight="bold" />
      <div>
        {countText(currentValue, previousValue)} vs prev. {timePeriodText}
      </div>
    </div>
  )
}
