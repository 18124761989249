import { Card, Loader } from 'src/design-system'
import * as React from 'react'

export type CheckinOverviewCardProps = {
  description: string
  difference?: React.ReactNode
  value: number | string
  loading?: boolean
}

export const CheckinOverviewCard = (props: CheckinOverviewCardProps) => {
  const { description, difference, value, loading } = props

  return (
    <Card className="flex items-center m-0 p-8 relative">
      <p className="mb-1 text-gray-600 text-xs">{description}</p>
      <p className="font-bold mb-0 text-gray-900 text-title">{value}</p>
      {difference && <div className="mb-0 mt-2 text-xs">{difference}</div>}
      {loading && (
        <div className="absolute inset-0 bg-white flex justify-center items-center">
          <Loader />
        </div>
      )}
    </Card>
  )
}
