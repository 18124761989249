import * as Table from 'components/table'
import * as React from 'react'
import { useOrgPeopleTable } from '../org-people-table/org-people-table-context'

export const OrgPeopleTablePagination = () => {
  const { table } = useOrgPeopleTable()

  return (
    <div className="absolute bg-white z-10 bottom-[1.5rem] left-[50%] rounded-full border border-gray-600 shadow-sm px-6 py-3 flex justify-center items-center translate-x-[-50%]">
      <Table.Pagination table={table} />
    </div>
  )
}
