import { CheckinFilters } from 'store/modules/checkins'
import {
  convertTimeValueToDateString,
  convertTimeValueToPreviousDateString,
  count,
} from '../utils'
import { InsightsFilters } from '../insights-engagement-page.types'
import { store } from 'store/index'

export class CheckinsSectionVm {
  constructor() {}

  get orgCheckinsUrl() {
    return this.org ? `/check-ins` : undefined
  }

  async fetchCurrentCount(filters: InsightsFilters, status?: string) {
    return await this.fetchCount({
      created_at_from: convertTimeValueToDateString(filters.time),
      status,
    })
  }

  async fetchPreviousCount(filters: InsightsFilters, status?: string) {
    if (filters.time === 'all_time') return undefined

    return await this.fetchCount({
      created_at_from: convertTimeValueToPreviousDateString(filters.time),
      created_at_to: convertTimeValueToDateString(filters.time),
      status,
    })
  }

  private get org() {
    return store.currentUser?.org
  }

  private async fetchCount(filter: CheckinFilters) {
    const response = await store.checkins.fetchAll({
      filter: { org_id: this.org?.id, ...filter },
      page: { size: 0 },
    })

    return count(response)
  }
}
